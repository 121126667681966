import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { Container as ContainerBase, ContentWithPaddingXl } from "components/misc/Layouts";
import { SectionDescription } from "components/misc/Typography";

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-400 text-center`;
const Description = tw(SectionDescription)`text-gray-600 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-md justify-between mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`

export default ({
  subheading = "provide a  stable yet relevant & sustainable source of secondary income to the people who have the appetite to invest.",
  heading = "Our Mission",
  description = "Our Scale"
}) => {
  const [stats, setStats] = useState([
    {
      key: "Clients",
      value: "25+",
    },
    {
      key: "Liable Capital",
      value: "₹1.3Cr+",
    },
    {
      key: "Team Size",
      value: "10+",
    },
  ]);
  useEffect(() => {
    let headers = new Headers();

    fetch('https://anthill-python-backend.herokuapp.com/api/v1/companyStats', {
      mode: "cors",
      method: "GET",
      headers: headers,

    })
      .then((response) => response.json())
      .then((data) => {
        setStats(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          <Heading>{heading}</Heading>
          {subheading && <Subheading>{subheading}</Subheading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <StatsContainer>
          {stats.map((stat, index) => (
            <Stat key={index}>
              <StatValue>{stat.value}</StatValue>
              <StatKey>{stat.key}</StatKey>
            </Stat>
          ))}
        </StatsContainer>
      </ContentWithPaddingXl>
    </Container>
  );
};
